import * as axios from 'axios'
import {translate} from "./i18n.js";
import Storage from './Storage.js'
import swal from 'sweetalert'

import store from '../../src/store.js'

export default class Network {
    static init() {
        axios.defaults.withCredentials = true;

        // handler 401 errors and redirect to the login
        axios.interceptors.response.use(resp => {
            if (resp.headers.hasOwnProperty(`x-renew-token`)) {
                this.setToken(resp.headers[`x-renew-token`]);
            }
            return resp;
        }, error => {
            this.handleError(error.response);
            return Promise.reject(error);
        });

        // compute baseUrl
        const websocketProtocol = window.location.protocol === `https:` ? `wss` : `ws`;
        if (window.env.VUE_APP_API_URL) {
            if (window.location.hostname.indexOf(`192.168.`) >= 0 || window.location.hostname.indexOf(`10.0.`) >= 0) {
                const apiPort = /:(\d{2,4})/g.exec(window.env.VUE_APP_API_URL)[1];
                this.baseUrl = `${window.location.protocol}//${window.location.hostname}:${apiPort}`;
                this.websocketBaseUrl = `${websocketProtocol}://${window.location.hostname}:${apiPort}`;
            } else {
                this.baseUrl = window.env.VUE_APP_API_URL;
                this.websocketBaseUrl = window.env.VUE_APP_API_URL.replace(`https`, websocketProtocol);
            }
        } else {
            this.baseUrl = ``;
            this.websocketBaseUrl = `${websocketProtocol}://${window.location.host}`;
        }
    }

    static async setToken(token) {
        axios.defaults.headers.common.Authorization = `Bearer ` + token;
        await Storage.set(`token`, token);
    }

    static async getToken() {
        return Storage.get(`token`);
    }

    static async removeToken() {
        delete axios.defaults.headers.common.Authorization;
        await Storage.remove(`token`);
    }

    static get(url, params, options) {
        return this.request(url, `GET`, params, options);
    }

    static post(url, params, options) {
        return this.request(url, `POST`, params, options);
    }

    static put(url, params, options) {
        return this.request(url, `PUT`, params, options);
    }

    static patch(url, params, options) {
        return this.request(url, `PATCH`, params, options);
    }

    static delete(url, params, options) {
        return this.request(url, `DELETE`, params, options);
    }

    static async websocketConnect(url) {
        const token = await this.getToken();
        if (token) {
            return new WebSocket(`${this.websocketBaseUrl}${url}?token=${token}`);
        } else {
            return new WebSocket(`${this.websocketBaseUrl}${url}`);
        }
    }

    static request(url, method, params, options) {
        if (!this.initialiazed) {
            Network.init();
            this.initialiazed = true;
        }

        if (!options) {
            options = {};
        }

        options.withCredentials = true;

        let baseUrl = this.baseUrl;
        if (options.external) {
            baseUrl = ``
        } else if (options.eye_intelligence_api) {
            baseUrl = window.env.VUE_APP_URLS__EYEINTELLIGENCE_API;
        } else if (options.system_api) {
            baseUrl = window.env.VUE_APP_URLS__SYSTEM_API;
        } else if (options.baseUrl) {
            baseUrl = options.baseUrl;
        }

        if (options.food_force_api) {
            baseUrl = window.env.VUE_APP_URLS__FOOD_FORCE_API;
        }

        url = baseUrl + url;

        if (localStorage.hasOwnProperty(`enable_debug`)) {
            if (url.indexOf(`?`) === -1) {
                url += `?`;
            } else {
                url += `&`;
            }
            url += `enable_debug=${localStorage.getItem(`enable_debug`)}`;
        }

        return new Promise((resolve, reject) => {
            if (!params) {
                params = {};
            } else {
                if (params.progressCallback) {
                    console.error(`Error progressCallback is a Network option, not a Network param`, url);
                }
            }

            let axiosOptions = {
                url: url,
                method: method
            };

            if (method === `GET`) {
                axiosOptions.params = params;
            } else {
                axiosOptions.data = params;
            }
            if (options) {
                if (options.onUploadProgress) {
                    axiosOptions.onUploadProgress = (progressEvent) => {
                        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader(`content-length`) || progressEvent.target.getResponseHeader(`x-decompressed-content-length`);
                        if (totalLength !== null) {
                            options.onUploadProgress(Math.round((progressEvent.loaded * 100) / totalLength), progressEvent);
                        }
                    };
                }

                if (options.onDownloadProgress) {
                    axiosOptions.onDownloadProgress = options.onDownloadProgress;
                }

                if (options.process_files) {
                    const formData = new FormData();
                    for (const key in params) {
                        if (params[key] !== null && typeof params[key] === `object` && params[key].constructor !== File) {
                            formData.append(key, JSON.stringify(params[key]));
                        } else {
                            formData.append(key, params[key]);
                        }
                    }
                    axiosOptions.data = formData;
                    axiosOptions.headers = {
                        'Content-Type': `multipart/form-data`
                    };
                }

                /*
                responseType: "text",
    responseEncoding: "base64"
                 */
                if (options.responseType) {
                    axiosOptions.responseType = options.responseType;
                }
                if (options.responseEncoding) {
                    axiosOptions.responseEncoding = options.responseEncoding;
                }
            }

            const currentURL = new URL(document.URL);
            const currentURLParams = new URLSearchParams(currentURL.search);
            if (currentURLParams.has(`enable_debug`)) {
                if (!axiosOptions.params) {
                    axiosOptions.params = {};
                }
                axiosOptions.params.enable_debug = currentURLParams.get(`enable_debug`)
            }

            let finished = false;

            let requestTimeout = null;
            if (!options.disableTimeout && !options.progressCallback) {
                requestTimeout = setTimeout(() => {
                    if (!finished) {
                        finished = true;
                        this.handleError({status: 408});
                        reject(new Error(`Request timeout`));
                    }
                }, options.timeout || 2 * 60 * 1000); // default timeout: 2min
            }

            axios(axiosOptions).then(resp => {
                if (resp.status === 202 && resp.data.id) {
                    // eslint-disable-next-line no-inner-declarations
                    const getProgress = () => {
                        axios.get(`${baseUrl}/progress/${resp.data.id}`)
                            .then(respProgress => {
                                if (respProgress.data.finished) {
                                    finished = true;
                                    if (requestTimeout)
                                        clearTimeout(requestTimeout);
                                    if (respProgress.data.error) {
                                        this.handleError(respProgress.data.error);
                                    } else {
                                        respProgress.data = respProgress.data.data;
                                        resolve(respProgress);
                                    }
                                } else {
                                    if (options.progressCallback)
                                        options.progressCallback(respProgress.data.progress);
                                    if (!finished) {
                                        setTimeout(getProgress, options.progressRefreshInterval || 2000);
                                    }
                                }
                            })
                            .catch(reject);
                    }

                    getProgress();
                } else {
                    finished = true;
                    if (requestTimeout)
                        clearTimeout(requestTimeout);
                    resolve(resp);
                }
            }).catch(err => {
                if (requestTimeout)
                    clearTimeout(requestTimeout);
                reject(err);
            });
        });
    }

    static handleError(error) {
        if (!error) {
            return;
        }
        if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
            store.dispatch(`logout`);
        }
        if (error.headers && error.headers.hasOwnProperty(`x-renew-token`)) {
            this.setToken(error.headers[`x-renew-token`]);
        }

        let errorText;
        if (error.data && error.data.stack) {
            if (typeof error.data === `string`) {
                errorText = error.data;
            } else if (error.data.stack) {
                errorText = error.data.stack;
            }
        }

        if (error.stack) {
            errorText = error.stack;
        }

        console.error(errorText || error);

        const isLocal = window.location.host.substr(0, 10) === `localhost:` || window.location.host.substr(0, 8) === `192.168.` || window.location.host.substr(0, 5) === `10.0.`;
        const isDev = window.location.host.substr(0, 4) === `dev.`;

        switch (error.status) {
            case 500:
                swal({
                    title: translate(`Network error|Erreur réseau`),
                    text: (isLocal || isDev) ? errorText : translate(`Something wrong happened|Une erreur s'est produite`),
                    icon: `error`
                });
                break;
            case 403:
                swal({
                    title: translate(`Access denied|Accès refusé`),
                    text: translate(`You don't have the permission to access this resource|Vous n'avez pas la permission d'accéder à cette ressource`),
                    icon: `error`
                });
                break;
            case 408:
                swal({
                    title: translate(`Request timeout|Délai dépassé`),
                    text: translate(`The server took too long to answer|Le serveur a pris trop de temps à répondre`),
                    icon: `error`
                });
                break;
        }

        return error;
    }
}
