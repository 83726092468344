<template>
    <div v-show="!loading" class="login-page">
        <section class="login-block">
            <div class="login-left">
                <div class="login-header">
                    <small v-tr>Welcome to|Bienvenue sur</small>
                    <div>Eye-Intelligence</div>
                </div>

                <div class="login-header-subsection">
                    <span v-tr>Reset my password|Nouveau mot de passe</span> <a @click="changeLanguage()" class="link-basic float-right" v-tr>Français|English</a>
                </div>

                <form class="login" @submit.prevent="changePassword">
                    <div class="form-group">
                        <label for="login-password" v-tr>New password|Nouveau mot de passe</label>
                        <b-input id="login-password" class="form-control" name="password" required v-model="password" type="password" placeholder=""/>
                    </div>

                    <div class="form-group">
                        <label for="login-repeat-password" v-tr>Confirm|Confirmation</label>
                        <b-input id="login-repeat-password" class="form-control" name="password" required v-model="repeatPassword" :state="passwordValid" type="password" placeholder=""/>
                    </div>
                    <div class="text-right">
                        <button type="submit" class="btn btn-default" v-tr>Update my password|Changer mon mot de passe</button>
                    </div>
                </form>

                <div class="text-center mt-3">
                    <p class="contact-us mt-3 mb-3" v-tr>Contact us|Contactez-nous</p>
                    <a class="login-link link-basic f-10" href="tel:+15146915115">514.395.9327</a><br>
                    <a class="login-link link-basic f-10" href="mailto:info@eyeinmedia.com">info@eyeinmedia.com</a>
                </div>

                <div class="login-footer">Eye-In Média 2021</div>
            </div>
            <div class="login-right">
                <b-carousel
                    :interval="4000"
                    controls
                    indicators>

                    <b-carousel-slide :img-src="`https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/login_azure.jpg`"></b-carousel-slide>
                    <b-carousel-slide :img-src="`https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/1_welcome_${language}.jpg`"></b-carousel-slide>
                    <b-carousel-slide :img-src="`https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/2_survey_${language}.jpg`"></b-carousel-slide>
                    <b-carousel-slide :img-src="`https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/3_newsletter_${language}.jpg`"></b-carousel-slide>
                    <b-carousel-slide :img-src="`https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/4_data_single_application_${language}.jpg`"></b-carousel-slide>
                    <b-carousel-slide :img-src="`https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/5_do_you_know_eyeintelligence_${language}.jpg`"></b-carousel-slide>
                    <b-carousel-slide :img-src="`https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/6_contact_${language}.jpg`"></b-carousel-slide>
                </b-carousel>
            </div>
        </section>
    </div>
</template>

<script>
    import Network from "../../vue-components/helpers/Network";
    import {changeLanguage} from "../../vue-components/helpers/i18n";

    export default {
        name: `reset-password`,
        data() {
            return {
                password: ``,
                repeatPassword: ``,
                loading: false
            }
        },
        computed: {
            passwordValid() {
                return this.password === this.repeatPassword;
            }
        },
        activated() {
            this.loading = false;
        },
        methods: {
            changeLanguage() {
                changeLanguage();
                this.$forceUpdate();
            },
            changePassword() {
                if (!this.passwordValid)
                    return;

                Network.post(`/users/reset-password`, {
                    username: this.$route.params.username,
                    activation_guid: this.$route.params.guid,
                    password: this.password
                }).then(() => {
                    this.$router.push(`/`);
                }).catch(() => {
                    this.$swal({
                        icon: `error`,
                        title: this.tr(`Link expired|Lien expiré`),
                        text: this.tr(`Your reset password link is not valid.|Votre lien de réinitilisation de mot de passe n'est pas valide.`)
                    });
                    this.$router.push(`/login`);
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .login-page {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: center;
        background: url("https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence/welcome-carousel/login_bg.jpg") no-repeat center;
        background-size: cover;
        min-height: 100%;
        height: auto;
        padding-top: 65px;
        padding-bottom: 20px;

        .login-block {
            position: relative;
            display: inline-block;
            padding: 0;
            width: 1280px;
            height: 800px;
            max-width: calc(100% - 20px);
            box-sizing: border-box;
            margin-top: 0;
            vertical-align: top;
            border-radius: 10px;
            box-shadow: none;
            font-size: 12pt;
            min-height: 550px;
            color: white;
            background: linear-gradient(8deg, #2b2838 0%, #415368 100%);
            overflow: hidden;

            .login-left {
                position: absolute;
                display: inline-block;
                padding: 40px 40px;
                top: 0;
                left: 0;
                width: 380px;
                height: 100%;
                vertical-align: top;

                .login-header {
                    font-size: 28px;
                    font-weight: lighter;
                    color: white;
                    font-variant: petite-caps;
                    letter-spacing: 2px;
                }

                .login-header-subsection {
                    font-weight: 100;
                    margin: 30px 0;
                    text-align: left;
                    letter-spacing: 2px;

                    .link-basic {
                        opacity: 0.5;
                        transition: 150ms;
                        color: inherit;
                        cursor: pointer;

                        &:hover {
                            text-decoration: underline !important;
                        }
                    }
                }

                label {
                    color: #56b9d7;
                    border-radius: 5px;
                    font-weight: 400;
                    font-size: 10pt;
                    line-height: 20px;
                    text-align: left;
                    letter-spacing: 2px;
                    margin-bottom: 2px;
                    margin-left: 10px;
                    width: 100%;
                }

                input {
                    padding: 5px 10px;
                    font-weight: 500;
                    font-size: 11pt;
                    border: none;
                    border-bottom: 2px solid #56b9d7;
                    color: white;
                    width: 100%;
                    background-color: transparent !important;
                    border-radius: 0;
                    margin-bottom: 20px;
                }

                button[type="submit"] {
                    cursor: pointer;
                    user-select: none;
                    border-radius: 50px;
                    color: #ffffff;
                    text-transform: initial;
                    font-weight: 400;
                    letter-spacing: 2px;
                    font-size: 12pt;
                    background: linear-gradient(to bottom, #52b9cc 0%, #2c70b6 100%);
                    box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.05);
                    padding: 12px 20px;
                    border: 0;
                    display: inline-block;
                    width: 100%;
                    transition: 150ms;

                    &:hover {
                        transform: scale(1.05);
                        background: linear-gradient(to bottom, #57ccdf 0%, #2c70b6 100%);
                    }

                    &:active {
                        transform: scale(0.95);
                        background: linear-gradient(to bottom, #51a3b6 0%, #285d9c 100%);
                    }
                }

                .button-forgot-password {
                    margin-bottom: 20px;
                    color: #56b9d7;
                    letter-spacing: 1px;
                }

                .contact-us {
                    font-size: 10pt;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    opacity: 0.8;
                }

                .login-divider {
                    margin: 10px;
                    font-size: 9pt;
                    letter-spacing: 1px;
                    opacity: 0.7;
                }

                .link-basic {
                    margin-bottom: 20px;
                    color: #56b9d7;
                    letter-spacing: 1px;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }

                .login-footer {
                    position: absolute;
                    left: 0;
                    bottom: 50px;
                    margin-top: 50px;
                    font-size: 9pt;
                    letter-spacing: 2px;
                    width: 100%;
                }
            }

            .login-right {
                position: absolute;
                width: calc(100% - 390px);
                display: inline-block;
                height: 100%;
                top: 0;
                right: 0;
                border-top-right-radius: inherit;
                border-bottom-right-radius: inherit;
            }
        }

        .carousel {
            height: 100%;

            &::v-deep .carousel-inner {
                height: 100%;

                .carousel-item {
                    height: 100%;

                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            &::v-deep .carousel-indicators li {
                border-radius: 50%;
                width: 6px;
                height: 6px;
                margin-right: 6px;
                margin-left: 6px;
            }
        }
    }

    @media(max-width: 576px) {
        .login-page {
            padding: 0;

            .login-block {
                max-width: 100%;

                .login-left {
                    padding: 15px;
                    width: 100%;
                }

                .login-right {
                    display: none;
                }
            }
        }
    }
</style>
